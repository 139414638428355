<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="/offline/appointments"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.appointmentDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="appointment">
      <!-- Title (Opt.) -->
      <ODNTitle
        v-if="appointment.title"
        :title="appointment.title"
        :icon="icons.calendarClearOutline"
      />

      <!-- Appointment Type -->
      <ODNSection
        :title="$t('sections.appointmentType')"
        :icon="icons.locateOutline"
      >
        <ODNBadge :color="appointment.appointmentType.color" full>
          {{ $t(`appointmentTypes.${appointment.appointmentType.name}`) }}
        </ODNBadge>
      </ODNSection>

      <!-- Date -->
      <ODNSection :title="$t('sections.date')" :icon="icons.calendarOutline">
        <ion-card>
          <ion-card-content>
            <p>
              {{
                $t('labels.startsAt', {
                  datetime: formatDate(appointment.startsAt),
                })
              }}
            </p>
            <p>
              {{
                $t('labels.endsAt', {
                  datetime: formatDate(appointment.endsAt),
                })
              }}
            </p>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Intervention -->
      <ODNSection
        :title="$t('sections.intervention')"
        :icon="icons.todayOutline"
      >
        <ion-card>
          <ion-card-content>
            <div v-if="appointment.intervention" class="odn-mab-8">
              {{
                $t('labels.intervention', {
                  date: formatDate(appointment.intervention),
                })
              }}
              <span class="odn-mal-4">
                ({{
                  $t(
                    `appointmentStatuses.${appointment.appointmentStatus.name}`
                  )
                }})
              </span>
            </div>
            <ion-button
              expand="block"
              color="primary"
              @click="onInterventionModal"
            >
              {{ $t('buttons.interventionDate') }}
              <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
            </ion-button>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Store -->
      <ODNSection
        v-if="appointment.store"
        :title="$t('sections.store')"
        :icon="icons.storefrontOutline"
      >
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>
              {{ appointment.store.retailer.name }} -
              {{ appointment.store.storeType.name }}
            </ion-card-subtitle>
            <ion-card-title>{{ appointment.store.name }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-row>
              <ion-col>
                <p>{{ appointment.store.address }}</p>
                <p>
                  {{ appointment.store.postcode }} {{ appointment.store.city }}
                </p>
                <p>{{ appointment.store.country }}</p>
              </ion-col>
              <ion-col class="ion-text-end">
                <p v-if="appointment.store.grade">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade') }} {{ appointment.store.grade }}
                </p>
                <p v-if="appointment.store.grade2">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade2') }} {{ appointment.store.grade2 }}
                </p>
                <p v-if="appointment.store.grade3">
                  <ion-icon :icon="icons.star"></ion-icon>
                  {{ $t('labels.grade3') }} {{ appointment.store.grade3 }}
                </p>
                <p v-if="appointment.store.code">
                  {{
                    appointment.store.retailer.storeCode ||
                      this.$t('labels.code')
                  }}
                  {{ appointment.store.code }}
                </p>
                <p v-if="appointment.store.code2">
                  {{ this.$t('labels.code2') }}
                  {{ appointment.store.code2 }}
                </p>
                <p v-if="appointment.store.code3">
                  {{ this.$t('labels.code3') }}
                  {{ appointment.store.code3 }}
                </p>
              </ion-col>
            </ion-row>
            <div>
              <p v-if="appointment.store.email">
                <ion-icon :icon="icons.mailOutline" class="ion-margin-end" />
                <a :href="`mailto:${appointment.store.email}`">
                  {{ appointment.store.email }}
                </a>
              </p>
              <p v-if="appointment.store.phone">
                <ion-icon :icon="icons.callOutline" class="ion-margin-end" />
                <a :href="`call:${appointment.store.phone}`">
                  {{ appointment.store.phone }}
                </a>
              </p>
            </div>
          </ion-card-content>
        </ion-card>
      </ODNSection>

      <!-- Survey / Project (Opt.) -->
      <ODNSection
        v-if="appointment.survey"
        :title="$t('sections.project')"
        :icon="icons.fileTrayOutline"
      >
        <!-- Project -->
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>
              {{ appointment.survey.project.customer.name }}
            </ion-card-subtitle>
            <ion-card-title>{{
              appointment.survey.project.name
            }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ODNBadges>
              <ODNBadge :color="appointment.survey.project.projectStatus.color">
                {{
                  $t(
                    `projectStatuses.${appointment.survey.project.projectStatus.name}`
                  )
                }}
              </ODNBadge>
              <ODNBadge>
                <ion-icon
                  :icon="icons.ellipse"
                  :style="{
                    color: appointment.survey.project.hasSurveys
                      ? '#2dd36f'
                      : '#eb445a',
                  }"
                />
                {{ $t('labels.hasSurveys') }}
              </ODNBadge>
              <ODNBadge>
                <ion-icon
                  :icon="icons.ellipse"
                  :style="{
                    color: appointment.survey.project.hasTickets
                      ? '#2dd36f'
                      : '#eb445a',
                  }"
                />
                {{ $t('labels.hasTickets') }}
              </ODNBadge>
            </ODNBadges>
            <div v-html="appointment.survey.project.notes"></div>
          </ion-card-content>
        </ion-card>
        <!-- Survey -->
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ appointment.survey.name }}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <div v-html="appointment.survey.notes"></div>
          </ion-card-content>
        </ion-card>
      </ODNSection>
      <!-- Notes -->
      <ODNSection
        v-if="appointment.notes"
        :title="$t('sections.notes')"
        :icon="icons.chatboxOutline"
      >
        <ion-card>
          <ion-card-content>
            <div v-html="appointment.notes"></div>
          </ion-card-content>
        </ion-card>
      </ODNSection>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/vue';
import {
  locateOutline,
  calendarClearOutline,
  calendarOutline,
  personOutline,
  storefrontOutline,
  fileTrayOutline,
  chatboxOutline,
  mailOutline,
  callOutline,
  star,
  ellipse,
  todayOutline,
} from 'ionicons/icons';

import ODNTitle from '@c/odn-title.vue';
import ODNSection from '@c/odn-section.vue';
import ODNBadges from '@c/odn-badges.vue';
import ODNBadge from '@c/odn-badge.vue';

export default {
  name: 'OfflineAppointmentDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonIcon,
    ODNTitle,
    ODNSection,
    ODNBadges,
    ODNBadge,
  },
  data() {
    return {
      icons: {
        locateOutline,
        calendarClearOutline,
        calendarOutline,
        personOutline,
        storefrontOutline,
        fileTrayOutline,
        chatboxOutline,
        mailOutline,
        callOutline,
        star,
        ellipse,
        todayOutline,
      },
    };
  },
  computed: {
    ...mapState('appointments', {
      appointment: 'selectedAppointment',
    }),
  },
  methods: {
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
  },
};
</script>
